.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
input:focus, input:focus-visible {
  outline: 0!important;
  box-shadow: none!important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 6px;
}
@media(min-width: 768px){
 
}
::-webkit-scrollbar {
    width: 5px;
  }
@media(max-width: 767px){
  ::-webkit-scrollbar {
    width: 0px;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* DirectLine bot css */
.webchat__basic-transcript {
  background-color: #fff;
}
.webchat__stacked-layout__avatar-gutter:has(.webchat__initialsAvatar--fromUser),
 .webchat__stacked-layout--from-user .webchat__stacked-layout__avatar-gutter, 
 .webchat__stacked-layout--from-user .webchat__stacked-layout__content .webchat__bubble__nub-pad, 
 .webchat__stacked-layout__main:has(.webchat__initialsAvatar--fromUser) .webchat__bubble__nub-pad, 
 .webchat__stacked-layout__main:has(.webchat__initialsAvatar--fromUser) + .webchat__stacked-layout__status .webchat__stacked-layout__avatar-gutter,
 .webchat__send-button,
 .webchat__stacked-layout__status {
  display: none !important;
}
.webchat__imageAvatar, .webchat__defaultAvatar {
 width: 25px!important;
 height: 25px!important; 
}
.webchat__stacked-layout__avatar-gutter {
  width: 15px!important;
}
.webchat__text-content, .ac-container {
padding-top: 5px!important;
}
.webchat__bubble__content, .webchat__send-box__main {
  border: 0!important;
}

.webchat__send-icon, .webchat__attachment-icon__icon, .webchat__icon-button__shade {
  display: none;
}
.webchat__icon-button[title='Upload file'] {
  border: 1px solid #d1d1d1;
  border-radius: 11px;
}
.webchat__send-box__main {
  position: relative;
}
.webchat__icon-button[title='Upload file']::before {
  content: "";
  background-image: url(./assets/images/plus-button.png);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
}
.webchat__send-box-text-box {
  border: 1px solid #e2e8f0;
    border-radius: 5px;
    height: 25px;
    padding: 4px 26px 4px 10px!important;
    margin-top: 8px;
    max-width: 72%;
    font-size: 12px;
}